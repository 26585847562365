import React, {useState, useContext, useEffect, Profiler} from 'react'
import { ShopContext } from '../../context/shop-context';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import img from './/../../assets/images/21.jpg'
import img2 from './/../../assets/images/22.jpg'
import img3 from './/../../assets/images/23.jpg'
import img4 from './/../../assets/icons/lobani.jpg'
import { Instagram  } from 'react-content-loader';

import { FaPhoneAlt } from "react-icons/fa";
import { FaSnapchat } from "react-icons/fa";
import { FiClock } from "react-icons/fi";
import { FaTiktok } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaShoppingCart } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import WorkingTime from './working-time';
import { useTranslation } from 'react-i18next';
import MapPage from '../map/Map';
import ImageSlide from '../image-slide/ImageSlide';


function Header() {
  const localhost_url = process.env.REACT_APP_IMAGE_URL;

const [t, i18n] = useTranslation();
  const {cartItems, addToCart, removeFromCart, updateCartItemCount, getTotalCartAmount, posts, profile} = useContext (ShopContext)
  const totalAmount = getTotalCartAmount();
  const coverImage = localhost_url + profile.cover;

  // const tax = (totalAmount * 15)/100;
  // const grandTotal = (totalAmount + tax);

  const [shows, setShows] = useState(false);
  const handleCloses = () => setShows(false);
  const handleShows = () => setShows(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showMap, setShowMap] = useState(false);
  const handleCloseMap = () => setShowMap(false);
  const handleShowMap = () => setShowMap(true);
  const {isLoading} = useContext(ShopContext);



 //const img4 = "https://www.google.com/url?sa=i&url=https%3A%2F%2Fstory.motherhood.com.my%2Fblog%2Faesthetic-cafe-to-visit-in-the-klang-valley-for-insta-worthy-posts%2F&psig=AOvVaw3Uq18x53I_sGLzyD1WJCI8&ust=1680552166000000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCODo0KG0jP4CFQAAAAAdAAAAABAQ"; 
  const img1 = "https://i0.wp.com/post.healthline.com/wp-content/uploads/2021/06/grain-bowl-1296x728-header.jpg?w=1155&h=1528"
  return (
    <div className='cart-items'>
       {isLoading ?  <Instagram  /> :
      <><Modal show={shows} onHide={handleCloses}>
          <div dir="ltr">
            <Modal.Header closeButton>
              <Modal.Title>{t('working-day')}</Modal.Title>
            </Modal.Header>
          </div>
          <Modal.Body>
            <WorkingTime />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="btn btn-dark text-white" onClick={handleCloses}>
              {t('close')}
            </Button>
          </Modal.Footer>
        </Modal><div className='cart-map'>
            <Modal show={showMap} onHide={handleCloseMap}>
              <div dir="ltr">
                <Modal.Header closeButton>
                  <Modal.Title>{t('location')}</Modal.Title>
                </Modal.Header>
              </div>
              <Modal.Body>
                <MapPage />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="btn btn-dark text-white" onClick={handleCloseMap}>
                  {t('close')}
                </Button>
              </Modal.Footer>
            </Modal>

          </div><div className="cart">
            <Modal show={show} onHide={handleClose}>
              <div dir="ltr">
                <Modal.Header closeButton>
                  <Modal.Title><h3>{t('cart-item')}</h3></Modal.Title>
                </Modal.Header>
              </div>
              <Modal.Body>
                <div className="cartItems">
                  <table className='table border-1'>
                    <thead>
                      <tr>
                        <th>{t('quantity')}</th>
                        <th>{t('item-name')}</th>
                        <th>{t('price')}</th>
                      </tr>
                    </thead>

                    {posts.map((product) => {
                      if (cartItems[product.id] !== 0) {
                        return (

                          <tr>
                            <td>
                              <div className="countHandler">
                                <button onClick={() => removeFromCart(product.id)}> - </button>
                                <input value={cartItems[product.id]}
                                  onChange={(e) => updateCartItemCount(Number(e.target.value), product.id)} />
                                <button onClick={() => addToCart(product.id)}> + </button>
                              </div>
                            </td>
                            {i18n.language == 'ar' ?
                              <>
                                <td>{product.ar_name}</td>
                                <td>{product.price}</td>
                              </>
                              :
                              <>
                                <td>{product.name}</td>
                                <td>{product.price}</td>
                              </>}

                          </tr>
                        );
                      }
                    })}
                    <tbody>
                    </tbody>
                  </table>
                </div>

                {totalAmount > 0 ?
                  <div className="checkout">
                    {i18n.language == "ar" ?
                      <div className="card text-end p-2">
                        <table dir='rtl'>
                          <tr>
                            <th><b>{t('total')}</b></th>
                            <td><b>{totalAmount} {t('sr')}</b></td>
                          </tr>
                          {/* <tr>
                    <th>{t('tax')} 15 %</th>
                    <td>{tax} {t('sr')}</td>
                    </tr>
                    <tr>
                    <th>{t('grand-total')}</th>
                    <td>{grandTotal} {t('sr')}</td>
                    </tr> */}
                        </table>
                      </div>
                      :
                      <div className="card text-start p-2">
                        <table className=''>
                          <tr>
                            <th><b>{t('total')}</b></th>
                            <td><b>{totalAmount} {t('sr')}</b></td>
                          </tr>
                          {/* <tr>
                        <th>{t('tax')} 15 %</th>
                        <td>{tax} {t('sr')}</td>
                        </tr>
                        <tr>
                        <th>{t('grand-total')}</th>
                        <td>{grandTotal} {t('sr')}</td>
                        </tr> */}
                        </table>

                      </div>}

                  </div> : <h5 div className='text-center'> {t('cart-empty')} </h5>}
              </Modal.Body>
              {totalAmount > 0 &&
                <div className="alert text-warning text-center">
                  <strong>{t('note')}</strong>
                </div>}
              <Modal.Footer>
                <Button variant="btn btn-dark text-white" onClick={handleClose}>
                  {t('close')}
                </Button>
              </Modal.Footer>
            </Modal>
          </div><div className="container-fluid my-3" dir='ltr'>
            <div className="card mb-3 ">
              <div className='cover-image'>

                {/* <img className="card-img-top" src={coverImage} /> */}
                <ImageSlide/>
              </div>
              <div className="card-body bg-light">


                <div className="row">
                  <button onClick={handleShows} className="col text-center"><FiClock color="" size="24px" /></button>

                  <button onClick={handleShowMap} className="col text-center"><FaMapMarkerAlt color="green" size="24px" /></button>

                  {/* <a href ={<Footer />} className="col text-center my-2"><FaMapMarkerAlt color="#cda141" size="18px" /></a> */}
                  {!!(profile.phoneNumber) && (profile.phoneNumber != 'null') ?
                    <a href={"tel:" + profile.phoneNumber} className="col text-center my-2"><FaPhoneAlt size="22px" color="#1877f2" /></a>
                    : <></>}

                  {!!(profile.snapchat_url) && (profile.snapchat_url != 'null') ?
                    <a href={profile.snapchat_url} className="col text-center my-2"><FaSnapchat color="#ffe000" size="30px" /></a>
                    : <></>}

                  {!!(profile.insta_url) && (profile.insta_url != 'null') ?
                    <a href={profile.insta_url} className="col text-center my-2"><AiFillInstagram color="#c32aa3" size="30px" /></a>
                    : <></>}

                  {!!(profile.tiktok_url) && (profile.tiktok_url != 'null') ?
                    <a href={profile.tiktok_url} className="col text-center my-2"><FaTiktok color="#010101" size="24px" /></a>
                    : <></>}
                  <button onClick={() => handleShow()} className='col text-center '><FaShoppingCart size='24px' color="" /></button>
                </div>

              </div>
            </div>
          </div></>
}
    </div>
  )
}

export default Header
