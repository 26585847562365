import React, { useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import './cart.css'
import { ShopContext } from '../../context/shop-context'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';



export default function Cart() {
  const {cartItems, addToCart, removeFromCart, updateCartItemCount, getTotalCartAmount, posts, show, handleClose} = useContext (ShopContext)
   const totalAmount = getTotalCartAmount()
   const navigate = useNavigate()
  return (
    <div className="cart">
         <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title><h3>Cart Items</h3></Modal.Title>
          </Modal.Header>
          <Modal.Body>
      <div className="cartItems">
      <table className='table border-1'>
              <thead>
                <tr>
                  <th>Quantity</th>
                  <th>Item Name</th>
                  <th>Price</th>
                </tr>
              </thead>
        
        {posts.map((product)=>{
            if(cartItems[product.id] !== 0 ){
         return( 

             <tr>
                <td>
                <div className="countHandler">
                <button onClick={()=>removeFromCart(product.id)}> - </button>
                <input value={cartItems[product.id]} 
                onChange={(e)=>updateCartItemCount(Number(e.target.value),product.id)}/>
                <button onClick={()=>addToCart(product.id)}> + </button>
                </div>
                </td>
                <td>{product.name}</td>
                <td>{product.price}</td>
                </tr>
        )}
        })}
            <tbody>
              </tbody>
            </table>
           
      </div>
      {totalAmount > 0 ?
      <div className="checkout">
        <div className="total text-center p-2">
        <h4> Total : {totalAmount} S.R.</h4>
        </div>
      </div> : <h5 div className='text-center'> Your cart is empty </h5>}
      </Modal.Body>
      <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>

          </Modal.Footer>
        </Modal>
    </div>

   
  )
}
