import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { ShopContext } from '../../context/shop-context';

function WorkingTime() {
  const {profile} = useContext(ShopContext);
  const [t, i18n] = useTranslation();
  return (
    <div className='Container'>
      <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>{t('day')}</th>
                <th>{t('opens')}</th>
                <th>{t('closes')}</th>
              </tr>
            </thead>
            <tbody> 
        
        <tr>
        <td>{t('sunday')}</td>
        {profile.sundayOpen =="null" ? <td></td> :
        <td>{profile.sundayOpen}</td>
        }
        {profile.sundayClose =="null" ? <td></td> :
        <td>{profile.sundayClose}</td>
        }
        </tr>
        <tr>
        <td>{t('monday')}</td>
        {profile.mondayOpen =="null" ? <td></td> :
        <td>{profile.mondayOpen}</td>
      }
        {profile.mondayClose =="null" ? <td></td> :
        <td>{profile.mondayClose}</td>
        }
        </tr>
        <tr>
        <td>{t('tuesday')}</td>
        {profile.tuesdayOpen =="null" ? <td></td> :
        <td>{profile.tuesdayOpen}</td>
      }
       {profile.tuesdayClose =="null" ? <td></td> :
        <td>{profile.tuesdayClose}</td>
        }
        </tr>
        <tr>
        <td>{t('wednesday')}</td>
        {profile.wednesdayOpen =="null" ? <td></td> :
        <td>{profile.wednesdayOpen}</td>
      }
       {profile.wednesdayClose =="null" ? <td></td> :
        <td>{profile.wednesdayClose}</td>
    }
        </tr>
        <tr>
        <td>{t('thursday')}</td>
        {profile.thursdayOpen =="null" ? <td></td> :
        <td>{profile.thursdayOpen}</td>
        }
        {profile.thursdayClose =="null" ? <td></td> :
        <td>{profile.thursdayClose}</td>
      }
        </tr>
        <tr>
        <td>{t('friday')}</td>
        {profile.fridayOpen =="null" ? <td></td> :
        <td>{profile.fridayOpen}</td>
        }
          {profile.fridayClose =="null" ? <td></td> :
        <td>{profile.fridayClose}</td>
      }
        </tr>
        <tr>
        <td>{t('saturday')}</td>
        {profile.saturdayOpen =="null" ? <td></td>:
        <td>{profile.saturdayOpen}</td>
    }

     { profile.saturdayClose =="null" ? <td></td> :
        <td>{profile.saturdayClose}</td>
     }
        </tr>
      </tbody>
          </table>
    </div>
  )
}

export default WorkingTime;
