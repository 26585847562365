import React, { useContext, useState } from 'react'
// import img1 from './../../assets/icons/favicon.ico';
import img2 from './../../assets/images/img-01.png'
import './../../assets/css/main.css'
import { toast } from 'react-toastify';
import { ShopContext } from '../../context/shop-context';
import { useTranslation } from 'react-i18next';
function ContactForm() {

    const [t, i18n] = useTranslation(); 
    const {store} = useContext(ShopContext);
    const BASE_URL = process.env.REACT_APP_API_URL;
    const MESSAGE_URL = `${BASE_URL}/messages`;

  
      const [data, setData] = useState({
        senderName : "",
        senderEmail : "",
        subject : "",
        message : "",
      });
  
  
        const handleSubmission = async (e) => {
          e.preventDefault();
          const formData = new FormData();
          formData.append("storeName", store);
          formData.append("senderName", data.senderName);
          formData.append("senderEmail", data.senderEmail);
          formData.append("subject", data.subject);
          formData.append("message", data.message);
          await fetch(MESSAGE_URL, {
            method: "POST",
            body: formData
            
          })
          .then((res)=>{
         
              if(!res.status){
                toast.success('فشل الإتصال بالخادم');
          
              }
              else if(res.status == 201){
                toast.success('تم الإرسال');
                setData({
                    senderName : "",
                    senderEmail : "",
                    subject : "",
                    message : "",
                })
              }
              else if(res.status == 500){
                toast.error('لايمكن معالجة الطلب الآن حاول مرة أخرى ');

        
              }
              else if(res.status == 404){
                toast.error('تأكد من الأتصال بالانترنت و حاولمرة أخرى');
   
              }
              else{
                toast.error('حدث خطأ الرجاء المحاولة مرة أخرى');
             
              }
          })
          .catch(()=>{
            toast.error('حدث خطأ الرجاء المحاولة مرة أخرى');
          });
        };
  
        function handle(e){
          const newdata={...data}
          newdata[e.target.id] = e.target.value
          setData(newdata)
        }

  return (
    <div class="contact1">
        
    <div class="container-contact1">
        
        <div class="contact1-pic js-tilt" data-tilt>
            <img src={img2} alt="IMG" />
        </div>
   

        <form class="contact1-form validate-form" onSubmit={handleSubmission}>
            
            <span class="contact1-form-title">
            {t('msg-title')}
            </span>

            <div class="wrap-input1 validate-input" data-validate = "Name is required">
                <input class="input1" type="text" id="senderName" name="senderName" placeholder= {t('s-name')} required 
                onChange={(e)=>handle(e)} value={data.senderName} />
                <span class="shadow-input1"></span>
            </div>

            <div class="wrap-input1 validate-input" data-validate = "Valid email is required: ex@abc.xyz">
                <input class="input1" type="number" id="senderEmail" name="senderEmail" placeholder={t('phoneNo')} 
                onChange={(e)=>handle(e)} value={data.senderEmail} />
                <span class="shadow-input1"></span>
            </div>

            <div class="wrap-input1 validate-input" data-validate = "Subject is required" >
                <input class="input1" type="text" id="subject" name="subject" placeholder={t('subject')} required
                onChange={(e)=>handle(e)} value={data.subject}/>
                <span class="shadow-input1"></span>
            </div>

            <div class="wrap-input1 validate-input" data-validate = "Message is required">
                <textarea class="input1" id="message" name="message" placeholder={t('msg')} required 
                onChange={(e)=>handle(e)} value={data.message}></textarea>
                <span class="shadow-input1"></span>
            </div>

            <div class="container-contact1-form-btn">
                <button class="contact1-form-btn">
                    <span>
                        {t('send')} 
                    <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                    </span>
                </button>
            </div>
        </form>
    </div>
</div>

  )
}

export default ContactForm
