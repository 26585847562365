
import react, { useEffect } from 'react';

function Visitor() {
    const BASE_URL = process.env.REACT_APP_API_URL;
    const VISITOR_URL = `${BASE_URL}/visitors`;
    let count = localStorage.getItem('page_view');
    let store = localStorage.getItem('storeName');

      useEffect(() => {
        const data = { 
          storeName: store,
          counter: count
        };
    
        fetch(VISITOR_URL, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data)
        })
        .then(response => {
         
        })
        .catch(error => {
            console.log(error)
        });
      }, []);
    
  return (
    <div>
      
    </div>
  )
}

export default Visitor
