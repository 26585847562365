import React, { useContext } from 'react'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { ShopContext } from '../../context/shop-context';

const proprietes = {
    duration: 3000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: true
}
  
  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: '100% 100%',
    height: '440px',
  }
  

const ImageSlide = () => {
    const {profile} = useContext(ShopContext);
    const localhost_url = process.env.REACT_APP_IMAGE_URL;
    let slide1 = localhost_url + profile.cover;

    let slide2;
    let slide3;
    if(!!(profile.cover_two) && profile.cover_two !='null'){
        slide2 = localhost_url + profile.cover_two;
    }else{
        slide2 = slide1;
    }
    if(!!(profile.cover_three) && profile.cover_three !='null'){
        slide3 = localhost_url + profile.cover_three;
    }else{
        slide3 = slide1;
    }
   
   


    const slideImages = [
        {
          url: slide1,
          caption: 'Slide 1'
        },
        {
          url: slide2,
          caption: 'Slide 2'
        },
        {
          url: slide3,
          caption: 'Slide 3'
        },
      ];

    return (
        <div className="slide-container">
            <Slide {...proprietes}>
            {slideImages.map((slideImage, index)=> (
            <div key={index} className='cover-image'>
              <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                {/* <span style={spanStyle}>{slideImage.caption}</span> */}
              </div>
            </div>
          ))} 
            </Slide>
        </div>
    )
}

export default ImageSlide;