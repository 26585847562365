import React, { useContext } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { ShopContext } from '../../context/shop-context';



  
  
function MapPage() {

  const {location} = useContext(ShopContext);

  const latitude = parseFloat(location.latitude);
  const longitude = parseFloat(location.longitude);
  console.log("latitude", latitude);
  console.log("longitude", longitude);
  const containerStyle = {
    width: '20rem',
    height: '400px'
  };

  const center = {
    lat: latitude,
    lng: longitude
  };

  
  return (
    
    <div className='div-map mx-0 px-0'>
        <center>
    <LoadScript
  googleMapsApiKey="AIzaSyAimPPU6lOY2_VQ00CjC4ODbgXLhAvUb4U"
>
  <GoogleMap
    mapContainerStyle={containerStyle}
    center={center}
    zoom={18}>
          <Marker lat={center.lat} lng={center.lng} />
  </GoogleMap>
</LoadScript>
</center>
</div>
  )
}

export default MapPage;
